import { Button, Empty, Pagination } from "antd";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import useRequest from "../../../hooks/useRequest";
import Dotted from "../../../assets/images/dotted.svg";
import Iconspace from "../../../assets/images/icon-spase.png";
import RightIcon from "../../../assets/images/right-errow.png";
import { dateString } from "../../../helper/functions";
import TransactionModal from "../../../components/TransactionModal";
import { useNavigate } from "react-router";
import Loader from "../../../components/Loader";

const SilverHistory = () => {
  const { request } = useRequest();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selected, setSelected] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [history, setHistory] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const showModal = (data) => {
    setIsModalOpen(true);
    navigate(`/transaction/detail`, { state: data });
  };

  const handleChangePage = (page) => {
    setPagination((prev) => ({
      ...prev,
      current: page,
    }));
    getHistory(page, pagination.pageSize);
  };

  const getHistory = (
    page = pagination.current,
    pageSize = pagination.pageSize
  ) => {
    setLoading(true);
    request({
      url: `/app/transaction/getByCategoryId/66069053fa639b23dcf4b3a8?page=${page}&limit=${pageSize}`,
      method: "GET",
      onSuccess: ({ data }) => {
        setLoading(false);
        setHistory(data.getData);
        setPagination({
          current: data.page,
          pageSize: data.pageSize,
          total: data.total,
        });
      },
      onError: (error) => {
        console.error("API Error:", error); // Debugging line
      },
    });
  };

  useEffect(() => {
    setLoading(true);
    getHistory();
  }, []);

  return (
    <div className="transaction-container">
      {loading ? (
        <Loader />
      ) : history.length ? (
        history.map((data, index) => (
          <div
            key={index}
            className="Transactions-header"
            onClick={() => {
              setSelected(data);
              showModal(data);
            }}
          >
            <div className="transactions-list">
              <h6>
                {data?.orderId?.typeOfTransaction}{" "}
                {data?.categoryId?.categories}
              </h6>
              <span>{data?.quantity?.toFixed(3)} oz</span>
            </div>
            <div className="date-text activity-date">
              
              <p>{dateString(data.dateOfTransaction, "ll")}</p>
              <div className="errow-r">
                <img width={7} src={RightIcon} alt="Right Icon" />
              </div>
            </div>
          </div>
        ))
      ) : (
        <Empty />
      )}

      <Pagination
        current={pagination.current}
        pageSize={pagination.pageSize}
        total={pagination.total}
        onChange={handleChangePage}
        style={{ marginTop: "20px" }}
      />

      {isModalOpen && (
        <TransactionModal
          data={selected}
          show={isModalOpen}
          hide={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
};

export default SilverHistory;
