import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Layout,
  Button,
  Row,
  Col,
  Typography,
  Form,
  Input,
  Checkbox,
  Select,
} from "antd";
import Logo from "../../assets/images/logo-black.svg";
import LogoWhite from "../../assets/images/Logo_new_mynt white.svg";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import { AuthContext } from "../../context/AuthContext";
import { useAppContext } from "../../context/AppContext";
import Loader from "../../components/Loader";
import encryptDecrypt from "../../helper/encryptDecrypt";
import apiUrl from "../../constants/api_urls";
import {
  getClientIP,
  getClientLocation,
  getClientBrowserName,
} from "../../helper/functions";
import lang from "../../helper/langHelper";
const { Title } = Typography;
const { Content } = Layout;

// ipAddress
const SignIn = () => {
  const { setEmail, setPassword } = useAppContext();
  const { setIsLoggedIn, setUserProfile, userProfile, language } =
    useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [ipAddress, setIpAddress] = useState();
  const [browser, setBrowser] = useState();
  const [location, setLocation] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const { request } = useRequest();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email, password } = values;
    let payload = {
      email: email,
      password: password,
    };
    setEmail(email);
    setPassword(password);
    onSubmit(payload);
  };

  const onSubmit = (values) => {
    const api = {
      login: apiUrl.login,
    };
    const { email, password } = values;
    if (!email)
      return ShowToast(lang("Please enter email to sign in"), Severty.ERROR);

    const payload = {
      password,
      ipAddress,
      browser,
      location: `${location?.city}, ${location?.country}`,
    };

    if (!email)
      return ShowToast(lang("Please enter valid email"), Severty.ERROR);

    setLoading(true);
    payload.email = email;
    request({
      url: api.login,
      method: "POST",
      data: payload,
      onSuccess: (data) => {
        setLoading(false);
        if (data.status == 203) {
          ShowToast(data.message, Severty.ERROR);
        } else if (data.status) {
          setIsLoggedIn(true);
          if (rememberMe) {
            var emailEncrypt = encryptDecrypt.encryptEmail(values.email);
            var passwordEncrypt = encryptDecrypt.encryptPassword(
              values.password
            );

            localStorage.setItem("rememberMe", "true");
            localStorage.setItem("ykmCe2AYEFTHobn", emailEncrypt);
            localStorage.setItem("ouUsippetc8S4Ry", passwordEncrypt);
          } else {
            localStorage.removeItem("ykmCe2AYEFTHobn");
            localStorage.removeItem("ouUsippetc8S4Ry");
          }

          localStorage.setItem("userProfile", JSON.stringify(data.data.user));
          // localStorage.setItem(
          //   "theme",
          //   JSON.stringify(data.data.user.dark_theme)
          // );
          localStorage.setItem("token", data.data.token);
          localStorage.setItem("theme", "true");
          localStorage.setItem("rememberMe", rememberMe);
          ShowToast(data.message, Severty.SUCCESS);
          setUserProfile(data.data.user);
          setTimeout(() => navigate("/"), 200);
        } else {
          ShowToast(data.message, Severty.ERROR);
        }
      },
      onError: (error) => {
        ShowToast(error?.response?.data?.message, Severty.ERROR);
        setLoading(false);
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("ykmCe2AYEFTHobn");
    const savedPassword = localStorage.getItem("ouUsippetc8S4Ry");

    if (savedEmail && savedPassword) {
      var originalEmail = encryptDecrypt?.decryptEmail(savedEmail);
      var originalPassword = encryptDecrypt?.decryptEmail(savedPassword);

      form.setFieldsValue({
        email: originalEmail,
        password: originalPassword,
        remember: true,
      });
      setRememberMe(true);
    } else {
      setRememberMe(false);
    }
  }, []);

  const getIPAndLocation = async () => {
    const ipAddress = await getClientIP();

    setIpAddress(ipAddress);
    const location = await getClientLocation(ipAddress);
    setLocation(location);
    const browser = getClientBrowserName(ipAddress);

    setBrowser(browser);
  };

  useEffect(() => {
    getIPAndLocation();
  }, []);

  return (
    <>
      {/* {
        loading && <Loader />
      } */}
      <Layout className="layout-default layout-signin">
        <Content className="signin d-none d-md-block">
          <Row className=" align-items-center">
            <Col span={24} sm={6} lg={6} md={6}>
              <div className="signup-logo d-dark-none">
                <img onClick={() => navigate("/")} src={Logo} alt="" />
              </div>
              <div className="signup-logo d-dark-block">
                <img
                  src={LogoWhite}
                  alt=""
                  onClick={() => navigate("/")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </Col>
            <Col span={24} md={18}>
              <div className="signup-right">
                <Link className="sign-in-link-type" type="button">
                  {lang("Don’t have a mynt account?")}
                </Link>
                <Button
                  className="sign-in-btn"
                  type="button"
                  onClick={() => navigate("/signup")}
                  htmlType="submit"
                >
                  {lang("Create an account")}
                </Button>
              </div>
            </Col>
          </Row>
        </Content>
        <div className="signin-box">
          <Row justify="space-around">
            <Col xs={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }}>
              <div className="h-sign-menu">
                <Title
                  className="tital-text-sign mb-15"
                  onClick={() => navigate("/")}
                >
                  {lang("Sign In")}
                </Title>
                <Button
                  className="ant-btn-default"
                  type="button"
                  onClick={() => navigate("/")}
                  htmlType="submit"
                >
                  {lang("Back")}
                </Button>
              </div>
              <div className="signup-form">
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  layout="vertical"
                  className="row-col"
                >
                  <Form.Item
                    className="username-form"
                    label={lang("Email")}
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: lang("Please enter a valid email address!"),
                      },
                      {
                        max: 255,
                        message: lang(
                          "Email address not more then 255 characters!"
                        ),
                      },
                      {
                        required: true,
                        message: lang("Please enter email!"),
                      },
                    ]}
                  >
                    <Input
                      className="form-of-type"
                      placeholder={lang("Enter Email ID")}
                    />
                  </Form.Item>

                  <Form.Item
                    className="username-form"
                    label={lang("Password")}
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: lang("Please enter password!"),
                      },
                    ]}
                  >
                    <Input.Password
                      className="form-of-type"
                      placeholder={lang("Password")}
                    />
                  </Form.Item>
                  {/* <div className="user-dirct">
                    By clicking “Sign in” below, I agree mynt’s User{" "}
                    <span className="ac-create">
                      <Link>Agreement</Link> and <Link> Privacy Policy.</Link>
                    </span>
                  </div> */}
                  <div className="forgot-pass">
                    <Form.Item
                      name="remember"
                      className="remember-type-text"
                      valuePropName="checked"
                    >
                      <Checkbox
                        checked={rememberMe}
                        className="rem-check"
                        onChange={(e) => {
                          setRememberMe(e.target.checked);
                        }}
                      ></Checkbox>
                      {lang("Remember my email address")}
                    </Form.Item>
                  </div>
                  <Form.Item className="signin-footer">
                    <Button
                      className="ant-btn-default"
                      type="submit"
                      htmlType="submit"
                      loading={loading}
                      // onClick={() => navigate("/")}
                    >
                      {lang("SIGN IN")}
                    </Button>

                    <a
                      className="forget-pass"
                      onClick={() => navigate("/forgot")}
                    >
                      {lang("Forgot your password?")}
                    </a>
                  </Form.Item>
                  <div className=" d-block d-md-none">
                    <div className="signup-right">
                      <Link className="sign-in-link-type" type="button">
                        {lang("Don’t have a mynt account?")}
                      </Link>
                      <Button
                        className="sign-in-btn"
                        type="button"
                        onClick={() => navigate("/signup")}
                        htmlType="submit"
                      >
                        {lang("Create an account")}
                      </Button>
                    </div>
                  </div>
                  <div className="signin-bottam-bar">
                    <span>
                      {lang("For trademarks and patents, please see our")}
                    </span>
                    <span
                      className="link-span"
                      onClick={() => navigate("/legal-notice")}
                    >
                      {" "}
                      {lang("Legal Notice.")}
                    </span>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </div>
      </Layout>
    </>
  );
};

export default SignIn;
