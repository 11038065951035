const CryptoJS = require("crypto-js");
const encryptReactPassword = process.env.REACT_APP_ENCRYPT_PASSWORD
  ? process.env.REACT_APP_ENCRYPT_PASSWORD
  : "EXPAHTYENCRIPTION";

const encryptEmail = (email) => {
  return CryptoJS.AES.encrypt(email, encryptReactPassword).toString();
};

const encryptPassword = (password) => {
  return CryptoJS.AES.encrypt(password, encryptReactPassword).toString();
};

// const decryptEmail = (email) => {
//   var byteEmail = CryptoJS.AES.decrypt(email, encryptReactPassword);
//   return byteEmail?.toString(CryptoJS.enc.Utf8);
// };

const decryptEmail = (email) => {
  try {
    if (!email) {
      throw new Error("Invalid input: Email is empty or null");
    }
    const bytes = CryptoJS.AES.decrypt(email, encryptReactPassword);
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);
    if (!decrypted) {
      throw new Error("Failed to decrypt email");
    }
    return decrypted;
  } catch (error) {
    console.error("Error decrypting email:", error);
    return null; // Return a fallback value or handle as needed
  }
};

const decryptPassword = (password) => {
  var bytePasssword = CryptoJS.AES.decrypt(password, encryptReactPassword);
  return bytePasssword.toString(CryptoJS.enc.Utf8);
};

export default { encryptEmail, encryptPassword, decryptEmail, decryptPassword };
