import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

export const GoldInfusion = () => {
  const { theme } = useParams();
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    if (theme === "dark") {
      setIsDarkTheme(true);
    } else {
      setIsDarkTheme(false);
    }
  }, [theme]);

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    // if (isDarkTheme === true || isDarkTheme == "true") {
    script.src = `https://widget.nfusionsolutions.com/widget/script/chart/1/b7140477-6a0d-44bb-b2af-92b65d32326c/54b3cb27-729e-4c24-8e0c-c4419ac81968?symbols=gold`;

    const firstScript = document.getElementsByTagName("script")[0];
    firstScript.parentNode.insertBefore(script, firstScript);
    // } else {
    //   script.src = `https://widget.nfusionsolutions.com/widget/preview/chart/1/b7140477-6a0d-44bb-b2af-92b65d32326c/54b3cb27-729e-4c24-8e0c-c4419ac81968`;

    //   const firstScript = document.getElementsByTagName("script")[0];
    //   firstScript.parentNode.insertBefore(script, firstScript);
    // }

    return () => {
      script.parentNode.removeChild(script);
    };
  }, [isDarkTheme]);

  return (
    <div>
      {/* {isDarkTheme === true || isDarkTheme == "true" ? (
        <div
          id="a78b82ea-2634-4f34-a66b-202807dac9ad"
          className="nfusion-chart"
          style={{ width: "100%", height: "100%" }}
        ></div>
      ) : ( */}
      <div
        id="54b3cb27-729e-4c24-8e0c-c4419ac81968"
        className="nfusion-chart"
        style={{ width: "100%", height: "100%" }}
      ></div>
      {/* )} */}
    </div>
  );
};

export default GoldInfusion;
